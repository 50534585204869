import { notification } from "antd";
import moment from "moment";
import {
  meetingRoomFAQBodySchema,
  privateOfficeFAQBodySchema,
  homeFAQBodySchema,
  meetingRoomBreadcrumbSchema,
  meetingRoomSimpleSchema,
} from "../../GO-Bermondsey_Schema";
import constants from "./Constants";

const {
  CMS_TYPES,
  CMS_PAGES,
  PRIVATE_OFFICE_FOLDS,
  MEETING_ROOM_FOLDS,
  DEDICATED_DESKS_FOLDS,
  FLEXI_DESKS_FOLDS,
} = constants;

const privateOfficeImg = "/assets/images/resource/private-office.jpg";
const dedicatedDeskImg = "/assets/images/resource/dedicated-desk.jpg";
const flexiDeskImg = "/assets/images/resource/flexi-desk.jpg";
const meetingRoomImg = "/assets/images/resource/meeting-room.jpg";

const privateOfficeBgImg = "/assets/images/resource/private-office-bg.jpg";
const dedicatedDeskBgImg = "/assets/images/resource/dedicated-desk-bg.jpg";
const flexiDeskBgImg = "/assets/images/resource/flexi-desk-bg.jpg";
const meetingRoomBgImg = "/assets/images/resource/meeting-room-bg.jpg";

const colors = {};
export const colorArr = [
  "#3ac48a",
  "orange",
  "#ffa16c",
  "#697789",
  "#329aff",
  "#4ed6e7",
  "#ff6378",
  "#893037",
  "#606371",
  "#E55B5B",
  "#2F5090",
  "#196EEF",
  "#5C33D1",
  "#9B51E0",
];
let lastColorIndex = 0;

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const sourceList = [
  "Search engine (Google, Yahoo, etc.)",
  "Recommended by friend or colleague",
  "Social media",
  "Blog or publication",
  "Other",
];

export const segmentedDays = ["Hours", "Days", "Months", "Longer"];

export const FOLD_TYPE = {
  default: {
    _id: "default",
  },
  testimonial: {
    _id: "testimonial",
  },
  faqs: {
    _id: "faqs",
  },
  list: {
    _id: "list",
  },
};

export const resourceTypes = {
  private_office: {
    _id: "private-offices",
    cms_id: "private_office",
    name: "Private Offices",
    desc: "Your own lockable Private Office",
    img: privateOfficeImg,
    bgImage: privateOfficeBgImg,
    folds: PRIVATE_OFFICE_FOLDS,
    cmsType: CMS_TYPES.SERVICE_TYPE.PRIVATE_OFFICE,
    cmsPage: CMS_PAGES.SERVICE_PAGE.PRIVATE_OFFICE,
    pageTitle:
      "Private Office Spaces from £4500 pcm in Bermondsey, London - GO Bermondsey....",
    pageDescription:
      "Private Office Space from £4500 pcm  in Maltby Street Market Bermondsey in London. Get an own private and lockable space with internet speeed 1GB.",
    pageCanonical: `https://go-bermondsey.co.uk/office-spaces/private-offices`,
    active: true,
    bodySchema: privateOfficeFAQBodySchema,
    bodyFaqSchema: homeFAQBodySchema,
  },
  meeting_room: {
    _id: "meeting-rooms",
    cms_id: "meeting_room",
    name: "Meeting Rooms",
    desc: "A space usually set aside for people to get together, often informally to hold meetings, for issues to be discussed, priorities set and decisions made",
    img: meetingRoomImg,
    folds: MEETING_ROOM_FOLDS,
    bgImage: meetingRoomBgImg,
    cmsType: CMS_TYPES.SERVICE_TYPE.MEETING_ROOM,
    cmsPage: CMS_PAGES.SERVICE_PAGE.MEETING_ROOM,
    pageTitle:
      "Meeting and Conference Rooms from £80/hr in Bermondsey, London - GO Bermondsey",
    pageDescription:
      "When you want to book meeting rooms in Bermondsey - London, GO Bermondsey is the ideal coworking space. Fully-equipped spaces for gathering of any size from £80 /hr.",
    pageCanonical: `https://go-bermondsey.co.uk/office-spaces/meeting-rooms`,
    active: true,
    bodySchema: meetingRoomSimpleSchema,
    bodyFaqSchema: meetingRoomFAQBodySchema,
    bodyBreadcrumbSchema:meetingRoomBreadcrumbSchema
  },
  dedicated_desk: {
    _id: "dedicated-desks",
    cms_id: "dedicated_desk",
    name: "Dedicated Desks",
    desc: "Your own Dedicated desk in a shared office",
    img: dedicatedDeskImg,
    folds: DEDICATED_DESKS_FOLDS,
    bgImage: dedicatedDeskBgImg,
    cmsType: CMS_TYPES.SERVICE_TYPE.DEDICATED_DESK,
    cmsPage: CMS_PAGES.SERVICE_PAGE.DEDICATED_DESK,
    pageTitle:
      "Dedicated Space Desks from £600 pcm in Bermondsey, London -GO Bermondsey",
    pageDescription:
      "Choose dedicated space desk to focus in private setting from £600 pcm in Bermondsey, London at GO Bermondsey. Enjoy the perks of networking at coworking space while having privacy.",
    pageCanonical: `https://go-bermondsey.co.uk/office-spaces/dedicated-desks`,
    active: false,
  },
  flexi_desk: {
    _id: "flexi-desks",
    cms_id: "flexi_desk",
    name: "Flexi Desks",
    desc: "Access to Flexible desks in shared spaces",
    img: flexiDeskImg,
    folds: FLEXI_DESKS_FOLDS,
    bgImage: flexiDeskBgImg,
    cmsType: CMS_TYPES.SERVICE_TYPE.FLEXI_DESK,
    cmsPage: CMS_PAGES.SERVICE_PAGE.FLEXI_DESK,
    pageTitle:
      "Hotdesk and Flexi Desk Space from £400 pcm in Bermondsey, London -GO Bermondsey",
    pageDescription:
      "GO Bermondsey has a premium hotdesk or flexi desk space set up at their coworking space in Bermondsey, London. On demand access to shared comman areas from £400 pcm.",
    pageCanonical: `https://go-bermondsey.co.uk/office-spaces/flexi-desks`,
    active: false,
  },
};

export function getAvatarColor(id, transparency) {
  if (!id) {
    return colorArr[Math.floor(Math.random() * colorArr.length)];
  }
  if (!colors.hasOwnProperty(id)) {
    colors[id] = colorArr[lastColorIndex];
    lastColorIndex = lastColorIndex + 1;
    lastColorIndex = lastColorIndex % colorArr.length;
  }
  return transparency ? `${colors[id]}${transparency}` : colors[id];
}

export const getNameInitials = (name) => {
  name = name && name.trim();
  if (!name) return;
  let initials = name[0] || "";
  var index = name.indexOf(" ");
  if (index < name.length && index > 1) {
    initials += name[index + 1];
  }
  return initials.toUpperCase();
};

export const checkSpecialCharacters = (name) => {
  let nameformat = /^[A-Za-z ]+$/;
  return nameformat.test(name);
};

export const validateEmail = (email) => {
  let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return mailformat.test(email);
};

export const showNotification = (type, msg) => {
  notification[type]({
    message: msg,
    placement: "bottomLeft",
  });
};

export const escapeRegex = (string) => {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const isValidMobileNumber = (countryCode = "", mobile = "") => {
  let format = /^[789]\d{9}$/;

  if (countryCode === "91") format = /^[6789]\d{9}$/;
  if (countryCode === "44") format = /^(07[\d]{8,12}|447[\d]{7,11})$/;

  if (!countryCode || !format) return false;

  return format.test(mobile);
};

export const disabledDate =
  (
    params = {
      isFutureDateDisabled: false,
      activeDays: days,
      toDisabledDate: null,
    }
  ) =>
  (current) => {
    const {
      isFutureDateDisabled = false,
      activeDays = days,
      toDisabledDate,
    } = params;

    const currentDay = moment(current).day();
    const isEnabled = activeDays.includes(days[currentDay]);
    if (!isEnabled) return true;

    // Can not select days after today
    if (isFutureDateDisabled) return current && current > moment().endOf("day");

    // Can not select days before from to disabled date
    if (toDisabledDate) {
      return current && current < moment(toDisabledDate).endOf("day");
    }

    // Can not select days before today
    return current && current < moment().startOf("day");
  };
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledHours = (workspace, from_date) => {
  const office_hours_start = workspace?.office_hours?.[0];
  const office_hours_end = workspace?.office_hours?.[1];
  let start_hour = moment(office_hours_start).format("HH");
  let end_hour = moment(office_hours_end).format("HH");
  let disabled_Hours = [...new Array(24).keys()]?.filter(
    (t) => t < start_hour || t > end_hour
  );

  if (from_date && moment().startOf("day").isSame(from_date, "day")) {
    let hr = moment().format("HH");
    let min = moment().format("mm");
    if (min > 55) ++hr;
    return [...new Set(disabled_Hours?.concat(range(0, hr)))];
  }
  return disabled_Hours;
};

export const disabledMinutes = (from_date, from_time) => {
  if (from_date && moment().startOf("day").isSame(from_date, "day")) {
    let min = moment().format("mm");
    if (moment(from_time).hour() === moment().hour()) {
      return range(0, min);
    }
  }
};


export const arrToObjMap = (arr = []) => {
  const obj = arr.reduce((mObj, item) => {
    var key = item?._id || item?.id;
    mObj[key] = item;
    return mObj;
  }, {});

  return Object.assign({}, obj);
};

export const getArrOrder = (arr = [], checkActive = false) => {
  let order = arr?.map((a) =>
    checkActive ? a?.active && (a?._id || a?.id) : a?._id || a?.id
  );
  order = order.filter((id) => id);

  return order;
};

export const formatDateTime = (value = "", type = "") => {
  switch (type) {
    //dt means format date and time
    case "dt":
      return moment(value).format("YYYY-MM-DDTHH:mm:ssZ");

    //d means date format only
    case "d":
      return moment(value).format("YYYY-MM-DD");

    //t means time format only
    case "t":
      return moment(value).format("HH:mm");

    default:
      return moment(value).format("DD-MM-YYYY HH:mm:ss");
  }
};

export const deskTypeCount = (deskItems = [], date = moment()) => {
  let desk_type = [];
  let desk_types_count = {
    flexi_desk: {
      total: 0,
      booked: 0,
    },
    dedicated_desk: {
      total: 0,
      booked: 0,
    },
  };

  if (Array.isArray(deskItems)) {
    deskItems.forEach((item) => {
      if (item?.status) {
        const today = date;
        const booking_slot = item?.booking_slot || [];
        const isBooked =
          booking_slot?.length > 0 &&
          booking_slot?.find((bs) =>
            today.isBetween(
              moment(bs?.from).startOf("day"),
              moment(bs?.to).endOf("day")
            )
          );

        desk_types_count[item?.desk_type] = Object.assign(
          {},
          desk_types_count[item?.desk_type],
          {
            total: (desk_types_count[item?.desk_type]?.total ?? 0) + 1,
            booked:
              (desk_types_count[item?.desk_type]?.booked ?? 0) +
              (isBooked ? 1 : 0),
          }
        );
      }
    });
  }

  Object.keys(desk_types_count).map((dt) =>
    desk_type.push({ [dt]: desk_types_count[dt] })
  );

  return desk_type;
};

export const searchStringToObjMap = (search = "") => {
  let searchString = search.replace("?", "");
  let params = {};

  searchString.split("&").forEach((ss) => {
    const param = ss.split("=");
    params[param[0]] = param[1];
  });

  return params;
};

export const calculateBusinessDays = (
  startDate,
  endDate,
  activeDays = days
) => {
  const currentDay = moment(startDate);
  let businessDays = 0;

  while (currentDay.isSameOrBefore(endDate, "days")) {
    if (activeDays.includes(days[currentDay.day()])) {
      businessDays++;
    }

    currentDay.add(1, "days");
  }
  return businessDays;
};

let debounceTimer;
export const debounce = (func, timeout = 1000) => {
  return ((...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  })();
};

export const removeInlineStyleColor = (s = "") => {
  if (typeof s === "string") {
    return s.replace(/color: ...*?;/gi, "");
  }

  return s;
};

export const setHeaderSpace = function () {
  function setPT() {
    setSpacer.style.paddingTop = headerData.offsetHeight + "px";
  }

  function myFunction() {
    if (window.pageYOffset > sticky) {
      headerData.classList.add("sticky-header");
    } else {
      headerData.classList.remove("sticky-header");
    }
    setPT();
  }
  const headerData = document.getElementById("mainHeader");
  const setSpacer = document.getElementById("setSpacer");
  const sticky = headerData.offsetTop;
  setPT();
  window.onscroll = function () {
    myFunction();
  };
};

export const addMinutesToTime = (time, minutesToAdd = 0) => {
  const [hours, minutes] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes + minutesToAdd;
  return totalMinutes;
};

export const durationMinutes = (duration = 0) => {
  return duration;
};

