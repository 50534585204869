
export const schemaHome = `
{
  "@context": "http://schema.org",
  "@type": "WebPage",
  "mainEntityOfPage": [
    {
      "@type": "WebPage",
      "name": "Private Offices| Serviced Workspaces to Rent in London | GO Bermondsey",
      "url": "https://go-bermondsey.co.uk/office-spaces/private-offices",
      "description": "Looking for Private Office Space in Bermondsey? Our fully serviced private offices start from £6000 pcm and are the perfect option for teams of up to 100. Book now!"
    },
    {
      "@type": "WebPage",
      "name": "Meeting & Conference Rooms from £40/hour | GO Bermondsey",
      "url": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms",
      "description": "Book Meeting Rooms in London on an hourly basis at GO Bermondsey. Experience the versatile meeting spaces as per your needs. Schedule a tour now!!!"
    }
  ],
  "isPartOf": {
    "@type": "WebSite",
    "name": "Go-Bermondsey",
    "url": "https://go-bermondsey.co.uk/",
    "publisher": {
      "@type": "Organization",
      "name": "GO Bermondsey",
      "url": "https://go-bermondsey.co.uk/",
      "description": "GO Bermondsey provides a fully serviced office workspaces in Bermondsey, London that suits all team sizes. Book a tour today and find your perfect business space.",
      "logo": "https://go-bermondsey.co.uk/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fnew_logo2.65a1ff00.png&w=256&q=75",
      "foundingDate": "2022",
      "founders": {
        "@type": "Person",
        "name": "Maltings Place Management Company Ltd"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+44 20 3465 9123",
        "contactType": "Support",
        "areaServed": "UK",
        "email": "info@go-bermondsey.co.uk"
      },
      "sameAs": [
        "https://www.facebook.com/GoBermondsey",
        "https://twitter.com/Go_Bermondsey",
        "https://www.instagram.com/gobermondsey/",
        "https://go-bermondsey.co.uk/blog/"
      ],
      "address": [
        {
          "@type": "PostalAddress",
          "streetAddress": "GO-Bermondsey Co-Working Space",
          "addressLocality": "37 Tanner Street SE1 3LF",
          "addressRegion": "Bermondsey St, Tower Bridge Rd, London",
          "postalCode": "SE1 3JB",
          "addressCountry": "GB"
        }
      ]
    }
  }
}
`;

export const homeBodySchema = `
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Go Bermondsey",
  "image": "https://go-bermondsey.co.uk/_next/static/media/new_logo2.2d33ffd1.png",
  "url": "https://go-bermondsey.co.uk",
  "telephone": "+44 (0) 203 465 9123",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "37 Tanner Street SE1 3LF(off Bermondsey Street) and Tower Bridge Road",
    "addressLocality": "London",
    "postalCode": "SE1 3JB",
    "addressCountry": "GB"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 51.5003071,
    "longitude": -0.079453
  },
  "sameAs": [
    "https://www.facebook.com/GoBermondsey",
    "https://twitter.com/Go_Bermondsey",
    "https://www.instagram.com/gobermondsey",
    "https://www.linkedin.com/company/go-bermondsey"
  ]
}
`;


export const homeFAQBodySchema = `
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Are all the workspaces furnished?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "GO Bermondsey's workspaces are fully furnished with modern elements. When outfitting these areas, the comfort of the employees and the aesthetic appeal of the workplace are both taken into consideration."
      }
    },
    {
      "@type": "Question",
      "name": "What are the different office sizes available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Whether you need a more compact workspace or a larger area for your bigger team, we have the perfect office for you in London to suit all your work requirements. You can get offices ranging in size from 500ft2 - 1350ft2"
      }
    },
    {
      "@type": "Question",
      "name": "Are GO-Bermondsey offices private or shared?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our London workspace offers private offices and meeting rooms ideal for all solopreneurs to large teams with shared coworking lounges for your collaboration and networking needs."
      }
    },
    {
      "@type": "Question",
      "name": "What’s the speed of the internet at shared workspaces in London?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "GO Bermondsey office spaces offers you high-speed broadband internet, with speeds of up to 1 GB. We want to make sure your work day does not suffer due to unnecessary buffering issues."
      }
    },
    {
      "@type": "Question",
      "name": "What are the amenities that are offered in serviced offices in London?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "GO Bermondsey office space amenities include, access to meeting rooms, office assistance, conference rooms, break rooms, recreational spaces, printing facilities, high-speed internet, kitchen facilities, and a secure parking area."
      }
    },
    {
      "@type": "Question",
      "name": "Are there any flexible lease options available for short-term or temporary workspace rentals?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we offer convenient, flexible lease choices that allow you to rent workspaces for any length of time, be it for a few hours, a couple of days or longer."
      }
    },
    {
      "@type": "Question",
      "name": "Can I customise the GO Bermondsey workspace to fit my specific business needs?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can customise office spaces as per your particular work requirements. Contact our dedicated team and they will assist you in tailoring the space to fulfil your needs. You can also, call us at +44 (0) 203 465 9123 or email us at info@go-bermondsey.co.uk"
      }
    },
    {
      "@type": "Question",
      "name": "Are utilities and maintenance costs included in the rental price?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, with our London office space the total rental price includes, service charges, utilities, high speed internet access, cleaning services, office assistance, kitchen amenities and more."
      }
    },
    {
      "@type": "Question",
      "name": "Is parking available for employees at the workspace in Bermondsey, London?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we provide flexible parking options at our London office space at Tanner Street, Bermondsey in London, including parking for your visitors at Maltings."
      }
    },
    {
      "@type": "Question",
      "name": "Can I schedule a tour or visit the GO-Bermondsey workspace before making a decision?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely, log on to our website and book yourself a visit. Take a guided tour through our versatile private offices and meeting rooms in London before committing to an agreement."
      }
    },
    {
      "@type": "Question",
      "name": "Is there any hidden cost when renting an office workspace?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "No, there is no hidden cost when you rent a private office space with GO Bermondsey. We believe in utmost transparency and all the amenities including, meeting rooms, service charges, utilities, high speed internet access, cleaning services, office assistance, kitchen amenities."
      }
    }
  ]
}
`;

export const homeVideoSchema = `
{
  "@context": "http://schema.org",
  "@type": "VideoObject",
  "name": "Introducing GO Bermondsey Workspaces in London",
  "description": "At Go Bermondsey we offer you to rent private office spaces, meeting rooms, and dedicated desks for a day and on an hourly basis. We are situated in the heart of London near London Bridge at Tanner Street. Check out the availability of office spaces and schedule a visit!!!",
  "thumbnailUrl": ["https://go-bermondsey.co.uk/assets/intro-poster.jpg"],
  "uploadDate": "2024-04-11",
  "duration": "PT0M32S",
  "contentUrl": "https://go-bermondsey.co.uk/assets/home-video.mp4",
  "regionsAllowed": [
    {
      "@type": "Place",
      "name": "UK"
    }
  ]
}
`;

export const meetingRoomFAQBodySchema = `
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "What types of meeting rooms do you offer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We offer our clients meeting rooms of different sizes, from small spaces to larger boardrooms. Each room has been uniquely designed, and comes equipped with state-of-the-art technology to support your meeting requirements. We can also customise your meeting room according to your needs."
      }
    },
    {
      "@type": "Question",
      "name": "Are your meeting rooms equipped with audiovisual equipment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, our meeting rooms offer you all the amenities you may require to facilitate your business meetings."
      }
    },
    {
      "@type": "Question",
      "name": "Can I book a meeting room for a few hours instead of a full day?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely, at GO Bermondsey we recognise the fact that no two clients or companies are the same. Accordingly, we offer you flexible hiring options. Book our meeting rooms for a few hours or longer, we leave the choice to you."
      }
    },
    {
      "@type": "Question",
      "name": "Are there any additional services available with the meeting rooms?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "When you book our meeting rooms, you are also offered access to other member facilities like office assistance, break rooms, recreational spaces, printing and scanning facilities, high-speed internet, kitchen facilities, and a secure parking area."
      }
    },
    {
      "@type": "Question",
      "name": "Are there public transportation options near GO Bermondsey?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our offices are conveniently located near the London Bridge tube station which lies in Zone 1 and is serviced by the Northern and Jubilee lines. London buses also service the area surrounding us."
      }
    },
    {
      "@type": "Question",
      "name": "Can I visit GO Bermondsey to see the meeting rooms before making a booking?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Certainly, we understand the need to inspect a space before hiring. Log onto our website and we shall book an appointment for you to visit us. Explore our meeting rooms on offer before signing up."
      }
    },
    {
      "@type": "Question",
      "name": "How do I book a GO Bermondsey meeting room?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To book a GO Bermondsey meeting room you can request a meeting room via the website under the Meetings Rooms/Book Online link, email us at info@go-bermondsey.co.uk or call us at 0203 465 9123."
      }
    },
    {
      "@type": "Question",
      "name": "How do I modify or cancel a booking?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To modify or cancel a booking with GO Bermondsey, email us at info@go-bermondsey.co.uk or call 0203 465 9123."
      }
    },
    {
      "@type": "Question",
      "name": "What happens if my meeting overruns or I need the room for longer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If your meeting runs longer than expected or you require the room for extended time, don't worry! We'll gladly accommodate you, provided the room is available. If not, we'll do our best to find an alternative space for you."
      }
    },
    {
      "@type": "Question",
      "name": "What is GO Bermondsey's guest policy for meeting room bookings?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "GO Bermondsey doesn't have a strict guest policy for meeting room bookings. However, we need to know in advance the number of attendees for logistical purposes."
      }
    }
  ]
}
`;

export const meetingRoomBreadcrumbSchema = `
{
  "@context": "https://schema.org/", 
  "@type": "BreadcrumbList", 
  "itemListElement": [{
    "@type": "ListItem", 
    "position": 1, 
    "name": "Home",
    "item": "https://GO-bermondsey.co.uk/"  
  },{
    "@type": "ListItem", 
    "position": 2, 
    "name": "Office Spaces",
    "item": "https://GO-bermondsey.co.uk/office-spaces/"  
  },{
    "@type": "ListItem", 
    "position": 3, 
    "name": "Meeting Rooms",
    "item": "https://GO-bermondsey.co.uk/office-spaces/meeting-rooms"  
  }]
}
`;

export const meetingRoomSimpleSchema = `
{
  "@context": "https://schema.org/",
  "@type": "WebPage",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://go-bermondsey.co.uk/office-spaces/meeting-rooms"
  },
  "headline": "Meeting Rooms",
  "description": "Book Meeting Rooms in London on an hourly basis at GO Bermondsey. Experience the versatile meeting spaces as per your needs. Schedule a tour now!!!",
  "image": [
    {
      "@type": "ImageObject",
      "url": "https://s3.us-east-2.amazonaws.com/ibism-dev/uploads/attachments/thumbnails/thumb_4gljadln1w42hm.png"
    },
    {
      "@type": "ImageObject",
      "url": "https://s3.us-east-2.amazonaws.com/ibism-dev/uploads/attachments/thumbnails/thumb_4gljadln1w42nk.png"
    },
    {
      "@type": "ImageObject",
      "url": "https://s3.us-east-2.amazonaws.com/ibism-dev/uploads/attachments/4gljadln1w4255.jpg"
    }
  ],
  "author": {
    "@type": "Organization",
    "name": "Go-Bermondsey"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Go-Bermondsey",
    "logo": {
      "@type": "ImageObject",
      "url": "https://go-bermondsey.co.uk/blog/wp-content/uploads/2022/10/cropped-animated-logo.png"
    }
  },
  "datePublished": "2024-02-19"
}
`;

export const privateOfficeFAQBodySchema = `
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Private Offices",
  "image": "https://s3.us-east-2.amazonaws.com/ibism-dev/uploads/attachments/thumbnails/thumb_14niqdksfl480nxsw.png",
  "@id": "",
  "url": "https://go-bermondsey.co.uk/office-spaces/private-offices",
  "telephone": "+44 (0) 203 465 9123",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "37 Tanner Street SE1 3LF (off, Bermondsey St, Tower Bridge Rd, London SE1 3JB",
    "addressLocality": "London",
    "postalCode": "SE1 3JB",
    "addressCountry": "United Kingdom",
    "addressRegion": "Bermondsey"
  },
  "priceRange": "from £6000 pcm for 12 desks to £16950 pcm for 30 desks"
}
`;


export const availabilityBreadcrumbSchema = `
{
  "@context": "https://schema.org/", 
  "@type": "BreadcrumbList", 
  "itemListElement": [{
    "@type": "ListItem", 
    "position": 1, 
    "name": "Home",
    "item": "https://go-bermondsey.co.uk/"  
  },{
    "@type": "ListItem", 
    "position": 2, 
    "name": "Availability",
    "item": "https://go-bermondsey.co.uk/availability"  
  }]
}
`;

export const availabilityFAQBodySchema = `
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How can I find available office space on GO Bermondsey",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Log in to our website at https://go-bermondsey.co.uk/ and select the 'Availability' option. A list of available office spaces for rent will appear. Choose the available office space that meets your requirements and click on the 'Book Tour' option. Fill in the required fields and submit the form, our team shall contact you. You could also email us at:- info@go-bermondsey.co.uk or call us on +44 (0) 203 465 9123."
    }
  },{
    "@type": "Question",
    "name": "What types of office spaces are available for rent on GO Bermondsey?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We offer a range of options tailored to support hybrid and flexible work patterns. Our commercial office spaces to rent include fully equipped private offices and meeting rooms for various team sizes. It is important to note that coworking spaces are available as part of an office rental package."
    }
  },{
    "@type": "Question",
    "name": "How do I inquire about an office space listed on GO Bermondsey?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Log in to our website at https://go-bermondsey.co.uk/ and click the 'Book Online' option. Fill in the required fields and a list of available offices will show up. Make a selection from the available office spaces for rent near London Bridge that meet your requirements. You will also find details such as floor plans, photos of the available London office spaces, and other essential information here. Go a step further and book yourself a visit to our offices."
    }
  },{
    "@type": "Question",
    "name": "Is GO Bermondsey only for businesses looking to rent available office spaces?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "GO Bermondsey isn't just for businesses in search of office spaces to rent. Our London office spaces in Bermondsey offer a versatile environment that welcomes a broad spectrum of professionals and creatives. From freelancers and entrepreneurs to remote workers, startups, and artists, our coworking space provides an array of opportunities. Whether you're looking for a private office or meeting room, our offerings are designed to meet diverse needs. GO Bermondsey is a place where collaboration flourishes, offering the ideal environment for your venture to grow."
    }
  },{
    "@type": "Question",
    "name": "What additional services or facilities are included with the rental?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "When you book an office space for rent, you are offered additional services such as business support services and access to shared office space amenities such as the use of communal areas and recreational facilities. Other amenities include access to coworking spaces (when you book a private office), meeting rooms, office assistance, conference rooms, printing facilities, high-speed internet, kitchen facilities, printing and scanning services, and a secure parking area."
    }
  },{
    "@type": "Question",
    "name": "Can I customise the commercial office space available for rent to suit the specific needs of my team or business?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, GO Bermondsey provides the flexibility to customise the available London office spaces according to your specific office requirements. Please reach out to our team, and they will discuss the customisation options with you. Feel free to call us at +44 (0) 203 465 9123 or email us at info@go-bermondsey.co.uk. We look forward to shaping your ideal workspace together!"
    }
  },{
    "@type": "Question",
    "name": "How do I find the best office space?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Finding the best office space to rent requires considering several factors such as location, size, amenities, and budget. At GO Bermondsey, we offer a diverse range of office spaces tailored to meet different needs. You can explore our options by logging into our website and clicking on the “Availability” option. This will present you with detailed information including floor plans, photos, and amenities, helping you make an informed decision."
    }
  },{
    "@type": "Question",
    "name": "What are the available office rental spaces in GO Bermondsey?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "GO Bermondsey offers a variety of office rental spaces designed to cater to various professional needs. Our offerings include fully equipped meeting rooms, private offices, shared desks, and coworking spaces. Each space is designed to support hybrid and flexible work patterns, suitable for freelancers, startups, and established businesses alike. For a detailed list of available office spaces to rent  and their features, please visit the 'Availability' section on our website."
    }
  },{
    "@type": "Question",
    "name": "How much office space do I need?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The amount of office space you need depends on several factors, including the number of employees, your desired layout, and any specific requirements such as meeting rooms or private offices. We at GO Bermondsey are happy to consult with you to determine the best fit for your team and business goals. Feel free to reach out to us directly for personalised advice.
      For more detailed information, including how to book a tour or discuss customization options, please contact us directly at +44 (0) 203 465 9123 or info@go-bermondsey.co.uk."
    }
  }]
}
`;

export const officeListingBodySchema = `
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Office Space in London",
  "url": "https://go-bermondsey.co.uk/office-spaces",
  "description": "GO Bermondsey fully serviced office spaces in London are consciously designed with amenities to help you and your team focus on what's important—building your business.",
  "image": "https://go-bermondsey.co.uk/_next/image?url=https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fibism-dev%2Fuploads%2Fattachments%2F4ghfuqllqctxp9.jpg&w=640&q=75",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://go-bermondsey.co.uk/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Office Spaces",
        "item": "https://go-bermondsey.co.uk/office-spaces"
      }
    ]
  },
  "mainEntity": {
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "How to rent office space in London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Renting office space in London is easy. Visit GO Bermondsey's website and follow the steps below. Click on the Book Now option. Choose the office space you want. Fill in your details: name, contact number, accommodation type, work email, date, and time. Next, fill in, “How did you hear about us?. Alternatively, you can write or call us at info@go-bermondsey.co.uk | +44 (0) 203 465 9123"
        }
      },
      {
        "@type": "Question",
        "name": "Does GO Bermondsey offer office spaces in the City of London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, GO Bermondsey’s fully serviced office spaces are conveniently located on Tanner Street in Bermondsey, just a 7-minute walk from London Bridge Station."
        }
      },
      {
        "@type": "Question",
        "name": "What are the different types of office spaces available in London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO Bermondsey offers coworking workspaces that foster collaboration and innovation. We provide private office spaces, meeting rooms, and dedicated desks to meet all your work requirements."
        }
      },
      {
        "@type": "Question",
        "name": "What factors should I consider when choosing office space in London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When choosing a shared office space in London, consider the location, amenities, technical support, and furnishings. GO Bermondsey offers a workspace that is fully furnished and equipped with everything you need to work smoothly. Also, our shared office spaces are easily accessible from the London Bridge station, making commuting easy."
        }
      },
      {
        "@type": "Question",
        "name": "Why should we choose GO Bermondsey for shared office spaces?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO Bermondsey, a leading office rental and coworking space provider, aims to create a fully serviced office community. Our shared office spaces are a flawless fusion of traditional and modern design, which sets us apart. Besides, our workspace is located at a prime location that connects you with the thriving business landscape in London."
        }
      },
      {
        "@type": "Question",
        "name": "How can I find commercial office spaces to rent?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO Bermondsey offers commercial office spaces to rent in London at an affordable price. Our office spaces have everything to streamline all your work needs, from essential furnishings to whiteboards and audio-visual needs."
        }
      },
      {
        "@type": "Question",
        "name": "What is the cost of renting an office space in GO Bermondsey, London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The cost of renting an office space at GO Bermondsey varies depending on your required office space. Private office rent starts from £6,000 pcm for 12 desks and goes up to £16,950 pcm for 30 desks. The Viaduct Offices rent starts at £7,800 + VAT pcm. Unit 10 - 10A office space costs £149 per square foot."
        }
      },
      {
        "@type": "Question",
        "name": "Can I rent office space in GO Bermondsey London for one day or just a few days?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can rent an office space in GO Bermondsey, London, for a day or a few days and gain access to the premium amenities provided to all members. Contact the GO Bermondsey team for more details."
        }
      },
      {
        "@type": "Question",
        "name": "What services are included in serviced offices in GO Bermondsey, London?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "GO Bermondsey's serviced offices in London offer a wide range of amenities, including access to dedicated meeting rooms, conference rooms, office assistance, break-out rooms, high-speed internet, printing facilities, kitchen facilities, and a secure parking area. These services are intended to improve your work experience and productivity within the office space."
        }
      }
    ]
  }
}
`;

